<template>
  <div class="box">
    <div class="header">
      <div class="sun-box" title="积云教育">
        <div id="sun_major"></div>
      </div>
      <div class="intro-box">
        <p class="title">欢迎来到</p>
        <p class="title">{{ majorInfo[major] ? majorInfo[major].name : "" }}</p>
        <p class="intro">{{ majorInfo[major] ? majorInfo[major].desc : "" }}</p>
        <div class="line"></div>
      </div>
      <router-link to="/jiyun_solar">
        <img class="btn-delete" src="../../../src/assets/usian/delete.png" />
      </router-link>
    </div>
    <div class="footer">
      <!-- <div class="swiper-button-prev"></div> -->
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div
              v-for="(item, index) in majorInfo[major]
                ? majorInfo[major]['imageAndText']
                : []"
              :key="index"
            >
              <div class="img-box" :style="`backgroundImage: url(${item.image})`">
                <!--                                <img class="btn-play" src="../../../src/assets/usian/btn-play.png"/>-->
              </div>
              <p class="sub-title">{{ item.name }}</p>
              <p>{{ item.salary }}</p>
              <p>{{ item.job }}</p>
              <p>{{ item.company }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="swiper-button-next"></div> -->
    </div>
  </div>
</template>

<script>
import * as THREE from "three/build/three.min.js";
// import Swiper from "swiper";
// import "swiper/css/swiper.min.css";
// import "swiper/js/swiper.min.js";

let rendererMajor = null;

export default {
  name: "jiyun_major",
  data() {
    return {
      major: "", //那个专业
      majorInfo: {
        mars: {
          name: "物联网",
          desc:
            "物联网即“万物相连的互联网”，是互联网基础上的延伸和扩展的网络，将各种信息传感设备与互联网结合起来而形成的一个巨大网络，实现在任何时间、任何地点，人、机、勿的互联互通。",
          imageAndText: [
            {
              image: require("../../assets/images/xingqiu/mars_1.png"),
              name: "陶*滨",
              company: "迈*科技",
              salary: "13K",
              job: "上海市葫芦岛"
            },
            {
              image: require("../../assets/images/xingqiu/mars_2.png"),
              name: "刘*旭",
              company: "聚量**信息科技",
              salary: "13K",
              job: "黑龙江海伦"
            },
            {
              image: require("../../assets/images/xingqiu/mars_3.png"),
              name: "郑*媛",
              company: "**美合科技",
              salary: "11K",
              job: "宁夏吴忠"
            }
          ]
        },
        saturn: {
          name: "人工智能",
          desc:
            "智械、机器智能，指由人制造出来的机器所表现出来的智能。通常人工智能是指通过普通计算机程序来呈现人类智能的技术。该词也指出研究这样的智能系统是否能够实现，以及如何实现。",
          imageAndText: [
            {
              image: require("../../assets/images/xingqiu/saturn_1.jpg"),
              name: "丁*凡",
              company: "西安贝业**服务有限公司",
              salary: "15K",
              job: "Python工程师"
            },
            {
              image: require("../../assets/images/xingqiu/mars_2.png"),
              name: "武*",
              company: "北京**软件股份有限公司",
              salary: "9K",
              job: "技术支持"
            },
            {
              image: require("../../assets/images/xingqiu/mars_3.png"),
              name: "毛*杰",
              company: "北京**科技有限公司",
              salary: "12.5K",
              job: "Python工程师"
            }
          ]
        },
        neptune: {
          name: "前端云计算",
          desc:
            "选择前端云计算就是选择了互联网的未来! 崛起时间短,发展迅速，前景远大！ 就业门路广，就业待遇好！",
          imageAndText: [
            {
              image: require("../../assets/images/xingqiu/neptune_1.png"),
              name: "李*程",
              company: "**诚信公司",
              salary: "20K",
              job: "全栈工程师"
            },
            {
              image: require("../../assets/images/xingqiu/neptune_2.png"),
              name: "范*梦",
              company: "远**科技有限公司",
              salary: "14K",
              job: "河南新乡"
            },
            {
              image: require("../../assets/images/xingqiu/neptune_3.png"),
              name: "田*毅",
              company: "今日**公司",
              salary: "22K",
              job: "H5全栈工程师"
            }
          ]
        },
        uranus: {
          name: "视觉设计",
          desc:
            "现代项目的开发，需要掌握多种技术。互联网项目，需要用到后端开发、前端开发、界面设计、产品设计、数据库、各种移动客户端、三屏兼容、restFul API设计和OAuth等等。",
          imageAndText: [
            {
              image: require("../../assets/images/xingqiu/uranus_1.jpg"),
              name: "杨*晶",
              company: "北京**云创软件技术有限公司",
              salary: "12.5K",
              job: "视觉设计师"
            },
            {
              image: require("../../assets/images/xingqiu/uranus_2.png"),
              name: "王*",
              company: "一只**科技有限公司",
              salary: "11K",
              job: "视觉设计师"
            },
            {
              image: require("../../assets/images/xingqiu/uranus_3.png"),
              name: "王*怡",
              company: "北京**恒通科技有限公司",
              salary: "13K",
              job: "UI设计师"
            }
          ]
        },
        pluto: {
          name: "全栈",
          desc:
            "现代项目的开发，需要掌握多种技术。互联网项目，需要用到后端开发、前端开发、界面设计、产品设计、数据库、各种移动客户端、三屏兼容、restFul API设计和OAuth等等， 比较前卫的项目，还会用到Single Page Application、Web Socket、HTML5/CSS3 这些技术以及像第三方开发像微信公众号微博应用等等。",
          imageAndText: [
            {
              image: require("../../assets/images/xingqiu/pluto_1.png"),
              name: "张*",
              company: "文思**技术有限公司",
              salary: "15K",
              job: "山西省运城"
            },
            {
              image: require("../../assets/images/xingqiu/pluto_2.png"),
              name: "王*",
              company: "奇**0有限公司",
              salary: "35K",
              job: "全栈工程师"
            },
            {
              image: require("../../assets/images/xingqiu/uranus_3.png"),
              name: "王*怡",
              company: "北京**恒通科技有限公司",
              salary: "13K",
              job: "UI设计师"
            }
          ]
        },
        jupiter: {
          name: "大数据",
          desc:
            "Java是大数据开发的第一语言，已经风靡全球20多年，依然是互联网的霸主，广泛应用于金融、电商、电信、数据中心、互联网、大数据等领域。目前，全球有数十亿的设备正在运行着Java , 90%以上的互联网大数据系统都采用Java进行后端开发。",
          imageAndText: [
            {
              image: require("../../assets/images/xingqiu/jupiter_1.png"),
              name: "任*涛",
              company: "北京**嘉兴科技有限公司",
              salary: "14K",
              job: "java开发工程师"
            },
            {
              image: require("../../assets/images/xingqiu/jupiter_2.png"),
              name: "杨*",
              company: "北京**华通科技有限公司",
              salary: "13K",
              job: "java开发工程师"
            },
            {
              image: require("../../assets/images/xingqiu/jupiter_3.png"),
              name: "王*龙",
              company: "国家电网",
              salary: "12K",
              job: "java开发工程师"
            }
          ]
        }
      }
    };
  },
  mounted() {
    this.major = this.$route.query.major;
    rendererMajor = new THREE.WebGLRenderer({ antialias: true, alpha: true }); //渲染器
    this.init();

    // new Swiper(".swiper-container", {
    //     loop: true,
    //     navigation: {
    //         nextEl: ".swiper-button-next",
    //         prevEl: ".swiper-button-prev"
    //     }
    // });
  },
  beforeDestroy() {
    if (rendererMajor) {
      rendererMajor.forceContextLoss();
      rendererMajor.domElement = null;
      rendererMajor = null;
    }
  },
  methods: {
    init() {
      this.createStar({
        type: "sun_major",
        width: this.getSize("sun_major").width,
        size: [this.getSize("sun_major").width / 2, 24, 24],
        img: require("../../../src/assets/usian/" + this.major + "_1024.jpg")
      });
    },
    createStar(params) {
      //场景
      var scene = new THREE.Scene();

      //灯光
      //点光源
      var pointLight = new THREE.PointLight(0xffffff);
      pointLight.position.set(10, 0, 10);
      scene.add(pointLight);

      //太阳光
      let directionalLight = new THREE.DirectionalLight(0xffffff, 4.0);
      directionalLight.position.set(0, 0, 10).normalize();
      scene.add(directionalLight);

      //摄像
      var camera = new THREE.PerspectiveCamera(60, 1, 1, 1000);
      camera.position.set(params.width * 0.72, 0, params.width * 0.72); //设置相机位置
      camera.lookAt(scene.position); //设置相机方向(指向的场景对象)

      //演员
      var geometry = new THREE.SphereBufferGeometry(
        params.size[0],
        params.size[1],
        params.size[2]
      );
      var material = new THREE.MeshPhongMaterial({
        color: 0xffffff
      });
      var textureLoader = new THREE.TextureLoader();
      textureLoader.load(params.img, function(tex) {
        material.map = tex;
        material.map.encoding = THREE.sRGBEncoding;
        material.needsUpdate = true;
      });
      var mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);

      //action
      rendererMajor.setClearAlpha(0);
      rendererMajor.setSize(params.width, params.width);
      document
        .getElementById(params.type)
        .appendChild(rendererMajor.domElement);
      render();

      function render() {
        if (rendererMajor) {
          rendererMajor.render(scene, camera);
          mesh.rotateY(0.001);
          requestAnimationFrame(render);
        }
      }
    },
    getSize(domStr) {
      var width = document.getElementById(domStr).offsetWidth;
      var height = document.getElementById(domStr).offsetHeight;
      return { width, height };
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin: 0;
  background: url("../../../src/assets/usian/bg-intro.jpg") center no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
}

.sun-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 积云教育 */
#sun_major {
  width: 500px;
  height: 500px;
}

.header {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

.intro-box {
  width: 330px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.title {
  font-size: 46px;
  margin: 5px 0;
  text-align: left;
}

.intro {
  font-size: 16px;
  line-height: 1.5;
  color: #a0a0a0;
  text-align: left;
}

.btn-play {
  width: 118px;
  height: 118px;
}

.btn-delete {
  width: 24px;
  height: 24px;
  position: fixed;
  right: 10.42vw;
  top: 11.11vh;
}

.line {
  width: 74px;
  height: 1px;
  background-color: #fff;
  margin-top: 30px;
}

.footer {
  display: flex;
  justify-content: center;
  position: relative;
}

.swiper-container {
  width: 50vw;
  font-size: 14px;
  color: #a0a0a0;
}

.swiper-slide {
  display: flex;
  justify-content: space-between;
}

.img-box {
  width: 15.62vw;
  height: 9.18vw;
  background: url(../../../src/assets/usian/video.jpg) center no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-play {
  width: 57px;
  height: 57px;
}

.sub-title {
  font-size: 16px;
  color: #fff;
  margin: 30px 0 18px 0 !important;
}

.swiper-slide p {
  margin: 8px 0;
}

.swiper-button-prev,
.swiper-button-next {
  outline: none;
  width: 56px !important;
  height: 56px !important;
  border-radius: 56px;
  background-color: #fff;
}

.swiper-button-prev {
  left: 18.75vw !important;
}

.swiper-button-next {
  right: 18.75vw !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 12px !important;
  font-weight: bold;
  color: #0f0c3e;
}
</style>
